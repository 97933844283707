import {
  EnvironmentVariables,
  FeatureFlagEnvironmentVariables,
} from "@/types/Environment";

const hasProcess = typeof process !== "undefined" && "env" in process;

/**
 * Get Environment Variable
 *
 * **NOTE**: This function handles `NEXT_PUBLIC_*` variables specially, for more information please check the
 * function's implementation.
 *
 * @param property Environment Variable Name
 * @param defaultValue Default Value
 */
const getEnv = (property: EnvironmentVariables, defaultValue = ""): string => {
  if (!hasProcess) {
    return defaultValue;
  }

  // Check if this is a feature flag variable
  if (isFeatureFlagVariable(property)) {
    return handleFeatureFlag(property, defaultValue);
  }

  if (isPublicEnvVar(property)) {
    return handlePublicEnvVar(property, defaultValue);
  }

  return process.env[property] || defaultValue;
};

/**
 * check if a variable is a feature flag environment variable
 */
function isFeatureFlagVariable(
  property: EnvironmentVariables,
): property is FeatureFlagEnvironmentVariables {
  return property.startsWith("NEXT_PUBLIC_FEATURE_");
}

/**
 * Handle feature flag environment variables
 */
function handleFeatureFlag(
  property: FeatureFlagEnvironmentVariables,
  defaultValue: string,
): string {
  switch (property) {
    case "NEXT_PUBLIC_FEATURE_DEGREE_DETAIL_REDESIGN_TRAFFIC":
      return (
        process.env.NEXT_PUBLIC_FEATURE_DEGREE_DETAIL_REDESIGN_TRAFFIC ||
        defaultValue
      );
    case "NEXT_PUBLIC_FEATURE_EXEC_ED_ATF_REDESIGN_TRAFFIC":
      return (
        process.env.NEXT_PUBLIC_FEATURE_EXEC_ED_ATF_REDESIGN_TRAFFIC ||
        defaultValue
      );
    case "NEXT_PUBLIC_FEATURE_PERSONALIZED_RESULTS":
      return (
        process.env.NEXT_PUBLIC_FEATURE_PERSONALIZED_RESULTS || defaultValue
      );

    default:
      console.warn(
        "Feature flag accessed that isn't inlined properly:",
        property,
      );
      return defaultValue;
  }
}

/**
 * check if a variable is a public environment variable
 */
function isPublicEnvVar(property: EnvironmentVariables): boolean {
  return property.startsWith("NEXT_PUBLIC_");
}

/**
 * Handle public environment variables (that are not feature flags)
 */
function handlePublicEnvVar(
  property: EnvironmentVariables,
  defaultValue: string,
): string {
  switch (property) {
    /* Pattern: Inlined Dot Syntax || Default */
    case "NEXT_PUBLIC_SITE_URL":
      return process.env.NEXT_PUBLIC_SITE_URL || defaultValue;
    case "NEXT_PUBLIC_COHESION_CONSENT_REQUIRED":
      return process.env.NEXT_PUBLIC_COHESION_CONSENT_REQUIRED || defaultValue;
    case "NEXT_PUBLIC_WORDPRESS_URL":
      return process.env.NEXT_PUBLIC_WORDPRESS_URL || defaultValue;
    case "NEXT_PUBLIC_APP_VERSION":
      return process.env.NEXT_PUBLIC_APP_VERSION || defaultValue;
    case "NEXT_PUBLIC_COMMERCE_COORDINATOR_BASE_URL":
      return (
        process.env.NEXT_PUBLIC_COMMERCE_COORDINATOR_BASE_URL || defaultValue
      );
    case "NEXT_PUBLIC_COMMERCE_COORDINATOR_ENABLE_PROGRAMS":
      return (
        process.env.NEXT_PUBLIC_COMMERCE_COORDINATOR_ENABLE_PROGRAMS ||
        defaultValue
      );
    default:
      console.warn("Variable accessed that isn't inlined properly:", property);
      return defaultValue;
  }
}

export const getEnvOrFail = (property: EnvironmentVariables): string => {
  const envVal = getEnv(property, "");

  if (envVal === "") {
    throw new Error(`Missing required environment variable: ${property}`);
  }

  return envVal;
};

export default getEnv;
